/* -------- grids  -------- */
.edi-viewport {
    .x-grid-cell {
        font-weight: 400;
        color: #1d252c;
        .status-DRAFT {
            color: #8e9295;
        }
        .x-grid-cell-inner-action-col {
            .x-action-col-icon:focus {
                outline: none;
            }
        }
    }
    .x-grid-header-ct {
        border: none;

        .x-column-header {
            border: none;
            overflow: hidden;

            &.x-group-header {
                .x-group-sub-header {
                    border-top: 1px solid silver;
                }
                &:after {
                    width: 0px;
                }
            }

            .x-column-header-inner {
                border: none;
                overflow: hidden;
                padding: 10px 12px;
                text-overflow: ellipsis;
                line-height: 20px;
                white-space: nowrap;

                &::after {
                    display: none;
                }

                .x-column-header-text-container {
                    //height: 20px;

                    .x-column-header-text {
                        font-style: normal;
                        font-size: 14px;
                        color: #1d252c;
                        white-space: nowrap;
                    }
                }

                &.x-column-header-over,
                &.x-column-header-open {
                    background-color: #ffffff;
                    border: 1px solid #e8e9e9;
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                }
            }

            &.edi-delfor-daily-graph-column {
                .x-column-header-inner {
                    padding: 7px 1px;
                }
                &.edi-delfor-daily-graph-column-weekend {
                    .x-column-header-text {
                        color: #f34235;
                    }
                }
            }
        }

        .x-column-header-text-inner {
           // display: flex;
            //align-items: start;

            &::after {
                font-family: 'EdiSoftIcons', sans-serif;
                font-size: 20px;
            }
        }

        .x-column-header-sort {
            cursor: pointer;
        }

        .x-column-header-sort-ASC,
        .x-column-header-sort-DESC {
            .x-column-header-text-inner:after {
                color: #8e9295;
                margin-left: 10px;
            }
        }

        .x-column-header-sort-ASC {
            .x-column-header-text-inner:after {
                content: "\e504";
            }
        }

        .x-column-header-sort-DESC {
            .x-column-header-text-inner:after {
                content: "\e503";
            }
        }

        .x-column-header-trigger {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            display: none;
            height: 100%;
        }

        .x-column-header-align-right{
            .x-column-header-text {
                margin-right: unset;
                .x-column-header-text-inner {
                    justify-content: end;
                }
            }
        }
    }

    .x-column-header-checkbox .x-column-header-checkbox:after,
    .x-grid-checkcolumn:after {
        content: "\e835";
        font-family: 'Material Icons', sans-serif;
        font-size: 22px;
        color: #525252;
    }

    .x-grid-hd-checker-on .x-column-header-checkbox:after,
    .x-grid-item-selected .x-selmodel-column .x-grid-checkcolumn:after {
        content: "\e834";
        font-family: 'Material Icons', sans-serif;
        font-size: 22px;
        color: #0085f5;
    }

    .x-grid-item-focused .x-grid-cell-inner:before {
        display: none;
    }

    .modal-grid {
        .x-panel-header-default-horizontal {
            padding: 0;
        }

        .x-panel-header {
            padding-left: 10px;

            .x-title-text {
                color: #212121;
                font-size: 18px;
            }
        }
    }

    .modal-grid-with-header .x-header-text {
        display: block;
    }

    .edi-coreplat-delivery-point {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #1D252C;
    }
    input.edi-coreplat-delivery-point {
        color: #000!important;
        padding: 4px 6px 3px 6px;
        background: #fff repeat-x 0 0;
        border: 0;
        margin-bottom: 2px;
        border-collapse: separate;
        box-shadow: 0 1px 0 0 silver;
        height: 24px;
        line-height: 15px;
    }

    .edi-delivery-grid-panel {
        padding-top: 5px;
        width: 100% !important;

        .modal-grid-with-header {
            .edi-grid-actions {
                padding-top: 0 !important;
            }
            .x-btn-glyph, .x-tool-tool-el {
                color: #ffffff !important;
            }
        }
    }

    .edi-grid-with-row-lines {
        border-top-width: 2px;
        border-color: rgba(29, 37, 44, .15);
        .x-panel-header {
            background-color: #3d7dca;
            border-radius:    3px;

            .x-panel-header-title-default {
                .x-title-text {
                    color: #ffffff;
                }
            }
        }

        .x-grid-item {
            background: transparent;
            &.x-grid-item-selected {
                border-color: #ededed;
                &.x-grid-item-over {
                    .x-grid-row {
                        background-color: #dce6f0;
                    }
                }
                .x-grid-row {
                    background-color: rgba(61, 125, 202, .15);
                    .x-grid-td {
                        background-color: transparent;
                    }
                }

                .x-grid-row.with-error {
                    background-color: #F0C5C6;
                }

                & + .x-grid-item {
                    border-top-color: #ededed;
                }
            }
            .x-grid-td {
                .x-form-text-wrap {
                    padding-left: 15px;
                }
            }
        }
    }

    .edi-grid-top-bar {
        padding: 16px 8px 8px 8px;
        border: none;

        .edi-grid-actions,
        .edi-grid-settings-button {
            padding: 10px;
            border-radius: 3px;
        }

        .edi-grid-actions {
            .x-btn-button {
                height: 20px;

                .x-btn-glyph {
                    width: 20px;
                    height: 20px;
                    font-size: 20px;
                    text-align: center;
                    color: #8e9295;
                }

                .x-btn-inner {
                    padding-left: 5px;
                    padding-right: 2px;
                    line-height: 20px;
                    font-weight: normal;
                }
            }

            &.x-btn-over {
                background: #f3f4f4;
            }

            &.x-btn-pressed,
            &.x-btn-menu-active {
                background: #e8e9e9;
            }

            &.x-btn-over,
            &.x-btn-pressed {
                .x-btn-glyph {
                    color: #1d252c;
                }
            }
        }
    }

    .x-grid {
        .x-docked-bottom {
            border-top-width: 1px !important;
            border-bottom-width: 1px !important;
            border-top-color: rgba(29, 37, 44, .15);
            border-bottom-color: rgba(29, 37, 44, .15);
        }
    }

    .edi-grid-paginator {
        padding: 0;

        .edi-grid-pagingbar-total-label {
            margin-left: 8px;
            margin-right: 32px;
            font-size: 14px;
            color: #1d252c;
        }

        .edi-tbar-total-label-right {
            font-size: 14px;
            color: #1d252c;
        }

        .edi-paging-bar-total-label-empty {
            color: #babdbf;
        }

        .edi-grid-pagingbar-refresh {
            margin-left: 8px;
        }

        .edi-paging-bar-request-time {
            color: #8E9295;
            opacity: 0.5;
            font-size: 14px;
            margin-left: 0;
        }

        .edi-grid-paginator-download-button {
            margin: 0 26px 0 18px !important;
        }

        .edi-grid-pagingbar-documents-per-page {
            margin: 0 22px 0 17px !important;

            .x-form-item-label-text {
                font-size: 14px;
                color: #1d252c;
                font-weight: 400;
            }

            .x-form-trigger-wrap {
                .x-form-field {
                    padding: 0;
                }
            }

            .x-unselectable {
                padding: 0;
                text-align: center;
                line-height: 26px;
                cursor: pointer;
                &:hover {
                    background:    #f2f3f3;
                    width: 95px;
                    border-radius: 4px;
                }
            }
        }
    }

    .edi-grid-paginator-new {
        .edi-paging-bar-total-label-empty {
            font-size:      14px;
            line-height:    18px;
            letter-spacing: 0.16px;
            color:          #babdbf;
        }
        a.x-btn.edi-label-button.x-btn {
            width: 40px;
            height: 40px;
        }
        a.x-btn.edi-label-button.x-btn-over,
        a.x-btn.edi-grid-pagingbar-refresh.x-btn-over {
            background-color: #f3f4f4;
            border-radius: 4px;
        }
        .x-btn-glyph {
            font-size: 24px;
        }
        &.edi-paging-bar-request-time,
        &.edi-tbar-total-label-right {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.16px;
        }
        &.edi-paging-bar-request-time {
            opacity: 1!important;
        }
        .edi-grid-pagingbar-documents-per-page-active {
            background: #E8E9E9;
            width: 95px;
            border-radius: 4px;
        }
    }

    .edi-grid-right-bar-for-config {
        padding: 0;
        overflow: visible;
        position: absolute;
        z-index: 5;

        .x-box-inner {
            position: absolute;
            overflow: visible;
        }

        .x-box-target {
            overflow: visible;
            margin-left: -35px;
            top: -1px;
        }

        .edi-grid-settings-button {
            padding: 10px;
            background-color: transparent;
            box-sizing: border-box;
        }
    }

    .modal-grid {
        .edi-grid-right-bar-for-config {
            .edi-grid-settings-button {
                padding: 8px 5px 8px 15px;
            }
        }
    }

    .edi-modal-form .edi-grid-right-bar-for-config {
        .x-box-target {
            top: 0;
        }
    }

    .edi-documents-grid {
        .edi-grid-right-bar-for-config {
            .x-box-target {
                overflow: visible;
                margin-left: -40px;
                top: -48px;
            }
        }

        .edi-documents-grid__total-warning-label {
            color: #a61120;
        }

        a.x-btn.edi-documents-grid__total-warning-details-button {
            .x-btn-inner {
                color: #a61120;
                text-decoration: underline;
            }
            &.x-btn-over {
                background-color: transparent;
                .x-btn-glyph {
                    color: #8e9295;
                }
            }
        }

        .x-grid-empty {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
            .edi-empty-text-with-button {
                text-align: center;
                .icon-empty-text {
                    background-image: url(images/grid/empty_text.png?ver=20150702?ver=20150702);
                    width: 216px;
                    height: 182px;
                    background-size: contain;
                    margin:0 auto;
                }
                p {
                    padding: 5px;
                    margin: 0;
                    font-size: 14px;
                }
                .edi-empty-btn {
                    margin:0 auto;
                    border: none;
                    background: none;
                    color: #3D7DCA;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }

    .edi-grid-settings-panel {
        background-color: #ffffff;
        border-radius: 3px;
        box-shadow: 0 4px 16px rgba(187, 189, 191, .5);

        .x-panel-body {
            padding: 16px 16px 0 16px;

            .edi-grid-settings-panel-checkboxgroup {
                .x-form-cb-label {
                    font-size: 14px;
                    color: #000000;
                    margin-left: 10px;
                    width: 200px;
                    line-height: 14px;
                    font-family: "Roboto", arial, verdana, sans-serif;
                }
            }
        }

        .edi-grid-settings-panel-toolbar {
            padding: 5px;
            background-color: #ffffff;
            font-weight: 500;
            border-top-width: 1px !important;

            .x-btn-glyph {
                color: #757575;
            }

            .x-btn-inner {
                line-height: 20px;
                color: #000000;
            }
        }
    }

    .edi-grid-settings-panel-toolbar,
    .edi-grid-paginator {
        .x-btn {
            padding: 10px;
            border-radius: 3px;
        }
    }

    .x-grid-item-container {
        box-shadow: 0 4px 8px rgba(187, 189, 191, .25)
    }

    .x-grid-cell {
        height: 40px;
        overflow: hidden;
        vertical-align: middle;
        background-color: #ffffff;
        border-right-width: 0;
        border-color: rgba(29, 37, 44, .15);
        color: #1d252c;
        border-width: 0;
        border-style: solid;

        .x-grid-cell-inner {
            overflow: hidden;
            padding: 0 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            zoom: 1;
            font-family: "Roboto",arial,verdana,sans-serif;
            &.x-grid-cell-inner-row-expander {
                padding: 0 6px;
                .x-grid-row-expander {
                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .x-grid-checkcolumn-cell-inner {
            text-overflow: clip;
        }

        &.edi-state-cell {
            .x-grid-cell-inner {
                display: flex;
                overflow: visible;
            }
        }
    }

    .x-action-col-cell {
        position: sticky;
        right: 0;
        z-index: 2;// 1 занят обычной ячейкой
        background-color: transparent !important;

        .x-grid-cell-inner-action-col {
            padding: 0;

            .x-action-col-icon {
                opacity: 0;
                padding: 10px;
                width: 40px;
                height: 40px;
                cursor: pointer;
                color: #8e9295;
                //background-color: #f3f4f4;

                &:hover {
                    color: #1d252c;
                    background-color: #f3f4f4;
                }

                &.x-item-disabled {
                    color: #bbbdbf;
                    //opacity: 1;
                    pointer-events: auto;
                    &:hover {
                        color: #bbbdbf;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    .x-grid-item-over {
        .x-grid-row {
            background-color: #f3f4f4;
        }

        .x-grid-td {
            background-color: transparent;
        }
        .x-action-col-cell {
            .x-action-col-icon {
                opacity: 1;
                background-color: #f3f4f4;
            }
        }
    }

    .x-panel-body {
        .x-grid-row.with-error {
            background-color: #F0C5C6;
            .x-grid-cell{
                background-color: #F0C5C6;
                .x-action-col-icon {
                    background-color: #F0C5C6;
                }
            }
        }
        .x-grid-row.yellow-cell {
            background-color: #fffbd5;
            .x-grid-cell {
                background-color: #fffbd5;
                .x-action-col-icon {
                    background-color: #fffbd5;
                }
            }
        }
    }

    .edi-modal-documents-selector, .edi-complex-request-json-input-form, .edi-modal-certificate-selector {
        .x-panel-body {
            .x-grid-data-row {
                .with-error {
                    .x-grid-cell {
                        background-color: rgb(239, 186, 186);
                    }
                }
            }

            .x-grid-row-alt {
                .with-error {
                    .x-grid-cell {
                        background-color: rgb(239, 186, 186);
                    }
                }
            }
        }
    }

    .edi-form {
        .x-panel-body {
            .x-tabpanel-child {
                .x-panel-body {
                    .x-grid-with-row-lines {
                        .x-grid-row {
                            .x-grid-data-row {
                                .with-error {
                                    .x-grid-cell {
                                        background-color: rgb(239, 186, 186);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .edi-main-modules-panel-body {
        .x-panel-body {
            .x-grid-row {
                .x-grid-data-row {
                    .with-error {
                        .x-grid-cell {
                            background-color: rgb(239, 186, 186);
                        }
                    }
                }
            }
            .x-grid-row-alt {
                .x-grid-data-row {
                    .with-error {
                        .x-grid-cell{
                            background-color: rgb(239, 186, 186);
                        }
                    }
                }
            }
        }
    }

    .x-grid-with-row-lines {
        border-top-width: 2px;
        border-color: rgba(29, 37, 44, .15);
    }
    //.x-grid-header-ct {
    //	margin-bottom: 2px;
    //}
    .x-toolbar.x-toolbar-default {
        border-color: rgba(29,37,44,.15);
        border-width: 1px 0 1px;
        background-image: none;
        background-color: #fff;
        .edi-tbar-btn-left, .edi-tbar-btn-right {
            border: 1px solid #ECECEC;
            box-sizing: border-box;
            border-radius: 3px 0 3px 3px;
            padding: 0;
            opacity: 1;
            margin-top: 0 !important;
        }
        .edi-tbar-btn-active {
            background-color: #3d7dca;
            .x-btn-inner{
                color: #fff;
            }
        }
        a.x-btn.edi-tbar-btn-active {
            .x-btn-wrap-default-toolbar-small {
                .x-btn-inner{
                    color: #fff;
                }
            }
        }
        a.x-btn.edi-grid-settings-button {
            background-color: #fff;
        }
    }

    .edi-grid-mark-empty-red, .edi-grid-products {
        .x-grid-empty {
            color: #f34235;
            font-weight: 400;
        }
    }
    .invalid {
        color: #f34235;
        font-weight: 400;
    }

    .x-panel-header-default-horizontal{
        padding: 8px;
    }

    .document-passport-time-mark-grid {
        .edi-grid-cell-flex {
            display: flex;
            height: 20px;
        }

        .x-grid-row {
            .edi-grid-cell-inner-button {
                opacity: 0;
            }

            &:hover {
                .edi-grid-cell-inner-button {
                    opacity: 1;
                    position: relative;
                    cursor: pointer;

                    .x-btn-icon {
                        background-color: transparent;
                        border: none;
                    }
                }
            }
        }
    }

    .x-grid-row-checker {
        height: 20px;
        width: 20px;
        &:before {
            font-size: 20px;
            color: #757575;
            font-family: Material Icons;
            content: '\e835';
            width: 15px;
            height: 15px;
            line-height: 14px;
            background: #fff;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
    .x-grid-item-selected {
        .x-grid-row-checker{
            &:before {
                color: #0085F5;
                content: '\e834';
            }
        }
    }
}