/* -------- edi-product-grid  -------- */
.edi-viewport .edi-product-grid-container {
    .x-panel-header {
        background-color: #3d7dca;
        border-radius: 3px;

        .x-title-text {
            color: #ffffff;
        }

        .x-btn {
            .x-btn-inner,
            .x-btn-glyph {
                color: #ffffff;
            }

            &.x-btn-over,
            &.x-btn-pressed {
                background-color: #2e96f5;

                .x-btn-inner,
                .x-btn-glyph {
                    color: #ffffff;
                }
            }
        }

        .x-tool {
            background-color: #3d7dca;
            border-radius: 3px;
            padding: 0;
            //padding: 5px;
            margin-left: 5px;

            &.x-tool-disabled {
                opacity: 0.5
            }

            .x-mask {
                background-color: transparent;
            }

            .x-tool-tool-el {
                color: #ffffff;
                background-color: transparent;
                font-size: 20px;
                width: 20px;
                height: 20px;
            }

            &.x-tool-over,
            &.x-tool-pressed {
                background-color: #2e96f5;
            }
        }

        .x-panel-header-title-default {
            color: #ffffff;
        }
    }
}