/* -------- company.selector  -------- */
BODY.edi-viewport {
    .edi-company-details-container-body {
        border-bottom-width: 1px !important;
    }

    .no-underline .edi-company-details-container-body {
        border-bottom-width: 0 !important;
    }

    .edi-company-details-with-errors {
        .edi-company-details-container-body {
            border-bottom: 2px solid #f34235 !important;
        }
    }

    .edi-company-selector-empty {
        .edi-company-details-container-body {
            margin-top: 12px;
        }
    }

    .edi-company-selector-read-only {
        .edi-company-details-container-body {
            margin-top: 0;
        }
    }

    .edi-company-details-container {
        .x-toolbar {
            padding: 4px;

            .x-btn-default-toolbar-small {
                padding: 0;
                width: 20px;
            }
        }

        .x-toolbar-default-docked-top {
            background-color: transparent;
        }

        .edi-company-details-container-body {
            top: 8px !important;
        }

    }

    .edi-company-row {
        .row-data {
            font-weight: normal;
            color: #000000;
            white-space: normal;
            font-size: 11px;
        }
    }

    .edi-selector-panel,
    .edi-modal-form .x-panel-body-default.edi-company-details-container-body {
        border-bottom: 1px solid #157fcc;
    }

    .edi-modal-form .edi-company-details-with-errors .x-panel-body-default.edi-company-details-container-body {
        border-bottom: 2px solid #f34235 !important;
    }

    .edi-selector-panel {
        .x-panel-body {
            top: 8px !important;
        }

        .x-toolbar-default-docked-top {
            background-color: transparent;
        }
    }

    .edi-company-name {
        font-weight: bold;
    }

    .edi-company-row {
        font-size: 11px;

        span {
            font-size: 10px;
            font-weight: bold;
            color: #b5b8c8;
        }
    }
}