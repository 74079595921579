@import '@UIkit/styles/typography/index';
@import '@UIkit/components/fields/Combobox/Combobox.scss';

.ui-comboboxfield-certs {
	@extend .ui-comboboxfield;
}

.ui-boundlist {
	&-item {
		&-subtext {
			display: inline-block;
			@extend .caption_01;
			color: $color-grey-50;
			margin: 4px 8px 0 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
