/* -------- страницы входа -------- */
BODY.edi-viewport {
    &.edi-viewport-with-background {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: #3d7dca;
    }

    .edi-login-form{
        padding-top:         60px;
        background-color:    #ffffff;
        background-image:    url(images/loginlogo_new.png);
        background-position: center 17px;
        background-repeat:   no-repeat;
    }

    &.edi-login-form-displayed {
        .x-mask-msg {
            display: none;
        }

        .edi-login-form-window {
            z-index: 90000 !important;
        }

        .x-tip {
            z-index: 90100 !important;
        }

        .edi-viewport-mask-msg,
        .edi-viewport-mask {
            display: none;
        }

        &.edi-viewport-background-winter-forest {
            background-image: url("backgrounds/winter-forest.jpg");
        }

        &.edi-viewport-background-winter-forest2 {
            background-image: url("backgrounds/winter-forest2.jpg");
        }

        &.edi-viewport-background-deer {
            background-image: url("backgrounds/deer.jpg");
        }

        &.edi-viewport-background-sea-cliffs {
            background-image: url("backgrounds/sea-cliffs.jpg");
        }

        &.edi-viewport-background-flower-with-dew {
            background-image: url("backgrounds/flower-with-dew.jpg");
        }

        &.edi-viewport-background-cloud-hill {
            background-image: url("backgrounds/cloud-hill.jpg");
        }

        &.edi-viewport-background-leaf-with-dew {
            background-image: url("backgrounds/leaf-with-dew.jpg");
        }

        &.edi-viewport-background-ferris-weel {
            background-image: url("backgrounds/ferris-weel.jpg");
        }

        &.edi-viewport-background-red-leaf {
            background-image: url("backgrounds/red-leaf.jpg");
        }

        &.edi-viewport-background-mountain-slope {
            background-image: url("backgrounds/mountain-slope.jpg");
        }

        &.edi-viewport-background-spring-leaves {
            background-image: url("backgrounds/spring-leaves.jpg");
        }

        &.edi-viewport-background-balloons {
            background-image: url("backgrounds/balloons.jpg");
        }

        &.edi-viewport-background-sand-beach {
            background-image: url("backgrounds/sand-beach.jpg");
        }

        &.edi-viewport-background-sakura {
            background-image: url("backgrounds/sakura.jpg");
        }

        &.edi-viewport-background-hills {
            background-image: url("backgrounds/hills.jpg");
        }

        &.edi-viewport-background-forest-glade {
            background-image: url("backgrounds/forest-glade.jpg");
        }

        &.edi-viewport-background-alfa-bank {
            background-image: url("backgrounds/alfa-bank.jpg");
        }

        &.edi-viewport-background-sparks {
            background-image: url("backgrounds/sparks.jpg");
        }

        &.edi-viewport-background-month-1 {
            background-image: url("backgrounds/monthly/01.jpg");
        }

        &.edi-viewport-background-month-2 {
            background-image: url("backgrounds/monthly/02.jpg");
        }

        &.edi-viewport-background-month-3 {
            background-image: url("backgrounds/monthly/03.jpg");
        }

        &.edi-viewport-background-month-4 {
            background-image: url("backgrounds/monthly/04.jpg");
        }

        &.edi-viewport-background-month-5 {
            background-image: url("backgrounds/monthly/05.jpg");
        }

        &.edi-viewport-background-month-6 {
            background-image: url("backgrounds/monthly/06.jpg");
        }

        &.edi-viewport-background-month-7 {
            background-image: url("backgrounds/monthly/07.jpg");
        }

        &.edi-viewport-background-month-8 {
            background-image: url("backgrounds/monthly/08.jpg");
        }

        &.edi-viewport-background-month-9 {
            background-image: url("backgrounds/monthly/09.jpg");
        }

        &.edi-viewport-background-month-10 {
            background-image: url("backgrounds/monthly/10.jpg");
        }

        &.edi-viewport-background-month-11 {
            background-image: url("backgrounds/monthly/11.jpg");
        }

        &.edi-viewport-background-month-12 {
            background-image: url("backgrounds/monthly/12.jpg");
        }
    }

    .edi-login-frame {
        visibility: hidden;
        width: 0;
        height: 0;
        position: absolute;
    }

    .edi-login-frame-sso {
        width: 100%;
        height: 100%;
        border: none;
        overflow: hidden;
    }

    .edi-login-form-window {
        border-radius: 10px;

        .x-window-header {
            background: transparent;
            border-color: transparent;

            .x-title-text {
                color: #ffffff;
            }
        }

        .edi-login-submit-button {
            background-color: #3d7dca;
            padding: 13px 10px;
            border-radius: 3px;
            height: 40px;
            text-transform: uppercase;
            font-weight: bold;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

            .x-btn-inner,
            &.x-btn-over .x-btn-inner,
            &.x-btn-pressed .x-btn-inner {
                font-size: 14px;
                font-weight: 700;
                color: #ffffff;
            }

            &.x-btn-over {
                background-color: #3d7dca;
                -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .6);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
            }

            &.x-btn-pressed {
                background-color: #3d7dca;
                border-top: 1px solid rgba(255, 255, 255, 1);
                border-left: 1px solid rgba(255, 255, 255, 1);
                -webkit-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .2);
                box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .2);
            }
        }

        .edi-login-form__field {
            position: relative;

            .x-form-item-body {
                width: 100%;

                .x-form-text {
                    border-width: 1px;
                    border-style: solid;
                    border-color: rgba(0, 0, 0, 0.5);
                    border-radius: 3px;
                    box-shadow: none;
                    padding: 12px 10px;
                    height: auto;
                    transition: all 0.2s ease-out 0s;
                    line-height: 15px;

                    &.x-form-invalid-field {
                        border-color: rgba(255, 0, 0, 1);
                    }
                }

                .x-form-trigger-wrap {
                    border-width: 0;

                    .x-form-trigger {
                        position: absolute;
                        top: 16px;
                        right: 6px;
                        min-width: 0;
                        margin-left: auto;
                    }
                }
            }

            .edi-login-form__field-label {
                .x-form-item-label-text {
                    color: rgba(0, 0, 0, 0.5);
                    position: absolute;
                    top: -4px;
                    left: 9px;
                    background-color: white;
                    padding: 0 3px;
                    transition: all 0.2s ease-out 0s;
                    z-index: 10;
                }
            }

            &:hover {
                .edi-login-form__field-label {
                    .x-form-item-label-text {
                        color: rgba(0, 0, 0, 1);
                    }
                }

                .x-form-text {
                    border-color: rgba(0, 0, 0, 1);
                }
            }

            input {
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                    -moz-box-shadow: 0 0 0 30px white inset !important;
                    box-shadow: 0 0 0 30px white inset !important;
                }
            }
        }

        .edi-password-recovery-link-container {
            margin-top: 30px;
        }

        .edi-password-recovery-link-container div,
        .edi-login-error-container div {
            text-align: center;
        }

        .edi-login-error-container {
            margin-top: 15px;
            min-height: 30px;
        }

        .edi-login-error-label {
            color: #f34235;
            font-size: 12px;
            line-height: 15px;
        }
    }

    .edi-viewport-footer {
        z-index: 90000;

        .x-panel-body {
            background: #212121;
            text-align: center;
            vertical-align: middle;
            line-height: 80px;
        }

        .edi-login-footer-link {
            display: inline-block;
            color: #ffffff;
            text-decoration: none;
            margin: 0 12px;

            &:hover {
                color: #959595;

                span {
                    text-decoration: underline;
                }
            }
        }

        .edi-login-footer-contact-email:before {
            font-family: 'Material Icons', sans-serif;
            content: "\E0D0";
            font-size: 22px;
            padding-right: 5px;
            vertical-align: middle;
        }

        .edi-login-footer-contact-phone:before {
            font-family: 'Material Icons', sans-serif;
            content: "\E0CF";
            font-size: 22px;
            padding-right: 5px;
            vertical-align: middle;
        }
    }
}