.edi-viewport {
    .edi-icon {
        font-size: 20px;
        line-height: 20px;
        width: 20px;
        height: 20px;
    }

    .edi-icon-COPY::before {
        content: '\e14d';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-MORE_VERT::before {
        content: '\e5d4';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-CLOSE::before {
        content: '\e14c';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-SEARCH::before {
        content: '\e8b6';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-RESET_ECD_VERSION::before {
        content: '\e042';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-DETAILS::before {
        content: '\e417';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-EDIT::before {
        content: '\e150';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-VISIBILITY::before {
        content: '\e417';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-LOOP::before {
        content: '\e863';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-CANCEL::before {
        content: '\e5c9';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-ADD::before {
        content: '\e148';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-MULTI_MODE::before {
        content: '\e8cd';
        font-family: 'Material Icons', sans-serif;
    }

    .edi-icon-MULTI_MODE_OFF::before {
        content: '\e92a';
        font-family: 'Material Icons', sans-serif;
    }
}