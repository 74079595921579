/* -------- edi-navigation-panel - панель навигации -------- */
.edi-navigation-panel {
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(176, 190, 197, 0.24);

    &-inner {
        border-collapse: separate;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        &-body {
            overflow-x: hidden !important;
            padding-top: 8px;
            padding-bottom: 54px;
            background-image: url("images/logo/logo_ediweb.png?ver=20220725");
            background-position: bottom 24px left 24px;
            background-repeat: no-repeat;
            background-attachment: local;
        }
    }

    .x-title.x-panel-header-title {
        .x-title-glyph {
            color: #8e9295;
            font-size: 20px;
        }
    }

    &-hover {
        overflow: visible;
        box-shadow: none;

        .edi-navigation-panel-inner {
            z-index: 90100;
            box-shadow: 0 32px 48px rgba(119, 124, 128, 0.5);
        }
    }

    &.edi-navigation-panel-closed {
        .categoryItemBody {
            display: none;
        }

        .edi-navigation-panel-inner-body {
            overflow: hidden !important;
            background-image: none;
        }
    }

    .categoryItem {
        width: 304px;

        .categoryItemHeader,
        .subCategoryItemHeader {
            background-color: transparent;
            border: none;
            padding: 10px 0 10px 10px;
            margin: 4px 8px;
            border-radius: 4px;

            .x-title-text {
                font-family: 'Roboto', arial, verdana, sans-serif !important;
                font-style: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #1d252c;
                cursor: pointer;
            }

            &:hover {
                background: #f3f4f4;
            }
        }

        .categoryItemHeader {
            .x-title-text {
                padding-left: 16px;
            }

            .categoryItemHeader-icon {
                padding: 0;
                display: none;
                right: 14px !important;
                left: auto !important;
                top: 2px !important;
                border: none;
                background-color: transparent;

                .x-btn-icon-el {
                    font-size: 20px;
                    width: 20px;
                    height: 20px;
                    color: #8e9295;
                }

                .x-btn-inner {
                    width: 20px;
                    height: 20px;
                }

                .x-btn-glyph {
                    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
                    transform: rotate(180deg);
                }
            }

            &:hover {
                .categoryItemHeader-icon {
                    display: inline-block;
                }
            }
        }

        &.active {
            .categoryItemHeader {
                .x-title-text,
                .x-title-glyph {
                    color: #3d7dca;
                }
            }

            .subCategoryItem.active .x-title-text {
                color: #1d252c;
                font-weight: 600;
            }
        }

        &.x-panel-collapsed {
            .categoryItemHeader-icon {
                .x-btn-glyph {
                    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0)";
                    transform: rotate(0deg);
                }
            }
        }

        .categoryItemSingleBody {
            border: none;
        }

        .categoryItemBody {
            padding: 0;
            border: none;

            .menuItem {
                .menuItemBody {
                    font-family: 'Roboto', arial, verdana, sans-serif !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #1d252c;
                    background-color: transparent;
                }

                &.active {
                    .menuItemBody {
                        font-weight: 600;
                    }
                }
            }

            .menuItem,
            .subCategoryItemHeader {
                border: none;
                border-radius: 4px;
                margin: 0 8px;
                padding: 10px 5px 10px 48px;
                cursor: pointer;
            }

            .menuItem:hover,
            .subCategoryItemHeader:hover {
                background-color: #e2ecf7;
            }

            .subCategoryItem.x-panel-collapsed .subCategoryItemHeader .x-title-glyph {
                -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
                transform: rotate(270deg);
            }

            .subCategoryItem {
                .subCategoryItemHeader {
                    border: none;
                    border-radius: 4px;
                    margin: 0 8px;
                    padding: 10px 5px 10px 48px;
                }
                .subCategoryItemBody {
                    border: none;

                    .menuItem {
                        padding-left: 90px;
                    }
                }
            }
        }
    }

    .categoryItem-with-delimiter {
        border-top: 1px solid #cbcecf;
    }
}