/* -------- date-picker -------- */
.edi-viewport {
    .x-datepicker {
        display: flex;
        border: 1px solid #d0d0d0;
        background-color: #fff;
        box-shadow:         0 0 5px 0 rgba(0, 0, 0, .2);
        &.x-datepicker-ranged {
            width: 450px;
        }
        &:not(.x-datepicker-ranged) {
            width: 212px;
            overflow: hidden;
            & > div {
                width: 100%;
            }
            .x-monthpicker {
                width: 100%;
                margin-left: 0;
                .x-monthpicker-months, .x-monthpicker-years {
                    width: 105px;
                }
                .x-monthpicker-months, .x-monthpicker-years {
                    border-width: 0 1px 0 0;
                    border-color: #e1e1e1;
                    border-style: solid;
                    width: 105px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    .x-monthpicker-item {
                        width: 52px;
                        height: 24px;
                        .x-monthpicker-item-inner {
                            line-height: 24px;
                            padding: 0;
                            color: #000;
                            border-radius: 0;
                            border-width: 1px;
                            &.x-monthpicker-selected {
                                background-color: #d6e8f6;
                                border-style: solid;
                                border-color: #3d7dca;
                            }
                            &:hover {
                                background-color: #eaf3fa;
                            }
                        }
                    }
                }
                .x-monthpicker-years {
                    .x-monthpicker-yearnav {
                        height: 34px;
                        padding: 0;
                        margin: 0;
                        .x-monthpicker-yearnav-button-ct {
                            width: 52px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: 'Material Icons', sans-serif;
                            .x-monthpicker-yearnav-prev {
                                &:before {
                                    content: "\e314";
                                }
                            }
                            .x-monthpicker-yearnav-next {
                                &:before {
                                    content: "\e315";
                                }
                            }
                        }
                    }

                }
                .x-monthpicker-buttons {
                    height: 25px;
                    background-color: #f5f5f5;
                    border: none;
                    .x-btn-default-small {
                        padding: 0;
                        .x-btn-inner {
                            font-size: 14px;
                            color: #3d7dca;
                        }
                    }
                }
            }
            .x-datepicker-inner {
                width: 100%;
                .x-datepicker-column-header {
                    width: 30px;
                    color: #000;
                    font: bold 13px "Roboto",arial,verdana,sans-serif;
                    text-align: right;
                    background-image: none;
                    background-color: #fff;
                    .x-datepicker-column-header-inner {
                        line-height: 25px;
                        padding: 0 9px 0 0;
                    }
                }
                .x-datepicker-cell {
                    text-align: right;
                    border-width: 1px;
                    border-style: solid;
                    border-color: #fff;
                    &.x-datepicker-active {
                        .x-datepicker-date {
                            color: #000;
                        }
                    }
                    &.x-datepicker-prevday {
                        .x-datepicker-date {
                            color: #bfbfbf;
                        }
                    }
                    &.x-datepicker-selected {
                        border: 1px solid #3d7dca;
                        .x-datepicker-date {
                            background-color: #d6e8f6;
                            font-weight: bold;
                        }
                    }
                    .x-datepicker-date {
                        padding: 0 7px 0 0;
                        font: normal 13px "Roboto",arial,verdana,sans-serif;
                        cursor: pointer;
                        line-height: 23px;
                        &:hover {
                            color: #000;
                            background-color: #eaf3fa;
                        }
                    }
                }
            }
            .x-datepicker-footer {
                padding: 6px 0;
                background-image: none;
                background-color: #f5f5f5;
                text-align: center;

            }
            .x-btn-default-small {
                padding: 0;
                .x-btn-inner {
                    color: #3d7dca;
                }
            }
        }
        .x-datepicker-header {
            padding: 4px 6px;
            text-align: center;
            background-image: none;
            background-color: #f5f5f5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            .x-datepicker-arrow {
                width: 12px;
                height: 12px;
                cursor: pointer;
                position: static;
                background-color: #f5f5f5;
                opacity: .7;
                &:hover {
                    filter:  progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
                    opacity: 1;
                }
                &:before {
                    content: '';
                }
                &.x-datepicker-prev{
                    left:             6px;
                    background-image: url(images/datepicker/arrow-left.png?ver=20150702);
                }
                &.x-datepicker-next{
                    right:            6px;
                    background-image: url(images/datepicker/arrow-right.png?ver=20150702);
                }
            }
            .x-datepicker-month {
                .x-btn-inner {
                    color: #3d7dca;
                    padding: 0;
                }
                .x-btn-wrap.x-btn-arrow {
                    &:after {
                        color: #999999;
                        right: 5px;
                        top: -1px;
                    }
                }
            }
            .x-btn-default-small {
                padding: 0;
            }
        }
        .x-datepicker-container {
            border-left: 1px solid #dddedf;
            width: 300px;
            padding: 5px 22px;
            .x-datepicker-header {
                background: none;
                margin-top: 20px;
                padding: 0;
                height: 24px;
                .x-datepicker-arrow {
                    font-family: "Material Icons";
                    font-size: 24px;
                    color: #8e9295;
                    background: none;
                    width: 24px;
                    height: 24px;
                    text-decoration: none;
                }
                .x-datepicker-month {
                    .x-btn-inner {
                        color: #1d252c;
                    }
                    .x-btn {
                        padding: 0;
                    }
                    .x-btn-over {
                        background: none;
                    }
                    .x-btn-arrow {
                        &:after {
                            content: '';
                            width: 0;
                        }
                    }
                }
            }
            .x-datepicker-inner {
                margin: 10px auto 20px;
                table-layout: auto;
                .x-datepicker-column-header {
                    color: #8e9295;
                    font-size: 11px;
                    background: none;
                }
                .x-datepicker-cell {
                    .x-datepicker-date {
                        font-size: 17px;
                        width: 34px;
                        margin: 2px auto;
                        padding: 0;
                        text-align: center;
                        line-height: 34px;
                        border-radius: 17px;
                        font-weight: normal;
                        & span {
                            display: none;
                        }
                    }
                    &.x-datepicker-active {
                        .x-datepicker-date {
                            color: #1d252c;
                        }
                    }
                    &.x-datepicker-today {
                        background: none;
                        .x-datepicker-date {
                            color: #3d7dca;
                        }
                    }
                    &.x-datepicker-selected {
                        .x-datepicker-date {
                            background-color: rgba(61,125,202,.5);
                            color: #fff;
                        }
                        &.x-datepicker-selectedInField {
                            .x-datepicker-date {
                                background-color: #3d7dca;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .x-datepicker-ranges {
            width: 150px;
            padding: 30px 0 0;

            .x-datepicker-range-type-title {
                color: #8e9295;
                font-weight: normal;
                font-size: 11px;
                padding: 0 0 12px 9px;
            }
            .x-datepicker-range-type-content {
                .x-datepicker-range-cnt {
                    font: normal 13px "Roboto",arial,verdana,sans-serif;
                    padding: 0 0 6px;
                    .x-datepicker-range {
                        padding: 4px 7px;
                        line-height: 22px;
                        border-radius: 14px;
                        border: 1px solid #fff;
                        color: #1d252c;
                        cursor: pointer;
                        text-decoration: none;
                        &:hover {
                            background-color: #e8e9e9;
                            border: 1px solid #e8e9e9;
                        }
                    }
                }
                .x-datepicker-range-cnt-selected {
                    .x-datepicker-range {
                        border: 1px solid #3d7dca;
                        color: #3d7dca;
                    }
                }
            }
        }
        .x-monthpicker {
            width: 310px;
            margin-left: 150px;
            .x-monthpicker-years {
                .x-monthpicker-years-inner {
                    margin: 10px auto 0;
                }
                .x-monthpicker-yearnav {
                    background: none;
                    padding: 15px 0 0;
                    height: 35px;
                    margin: 0 25px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &-button {
                        font-family: "Material Icons";
                        font-size: 24px;
                        color: #8e9295;
                        background: none;
                        width: 24px;
                        height: 24px;
                        text-decoration: none;
                        margin: 0;
                        &:before {
                            content: '';
                        }
                    }
                }
            }
            .x-monthpicker-months {
                .x-monthpicker-months-inner {
                    margin: 0 auto;
                }
            }
            .x-monthpicker-months, .x-monthpicker-years{
                width: 150px;
                .x-monthpicker-item {
                    width: auto;
                    float: none;
                }
            }
            .x-monthpicker-item {
                .x-monthpicker-item-inner {
                    text-align: center;
                    border-radius: 17px;
                    line-height: 34px;
                    padding: 0 2px;
                    color: #1d252c;
                    font-weight: normal;
                    &.x-monthpicker-selected {
                        background-color: #3d7dca;
                        color: #fff;
                    }
                }
            }
            .x-monthpicker-buttons {
                background-color: #fff;
                border-top: 1px solid #dddedf;
                padding: 3px 0;
                .x-btn-over {
                    background: none;
                }
                .x-btn-inner {
                    color: #8e9295;
                    font-size: 12px;
                }
            }
        }
    }
    .x-datepicker-range-type {
        padding-left: 15px;
        margin-bottom: 10px;
    }
    .x-datepicker-range-cnt {
        font: normal 13px "Roboto",arial,verdana,sans-serif;
        padding: 0 0 6px;
    }
    .x-form-date-trigger {
        &:before {
            font-family: Material Icons;
            content: '\e916';
            font-size: 20px;
            height: 20px;
        }
    }
}