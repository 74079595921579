/* -------- edi-tab-bar - таб-панель -------- */
.edi-viewport {
    .edi-tab-bar.x-tab-bar {
        .x-tab-bar-body {
            padding: 0;
            background-color: #ececec;
        }

        .x-box-scroller {
            margin-top: -16px;
            background-color: transparent;
            color: #808080;
        }

        .x-tab {
            display:          inline-block;
            position:         absolute;
            margin:           0;
            padding:          10px 16px;
            font-style:       normal;
            font-weight:      normal;
            line-height:      18px;
            font-size:        16px;
            overflow: visible;
            outline: 0!important;
            background-color: transparent;
            white-space: nowrap;
            text-decoration: none;
            text-align: center;
            float: left;
            border-radius: 8px 8px 0 0;
            border-collapse: separate;
            cursor: pointer;

            .x-tab-wrap {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                width: auto;
            }

            .x-tab-inner {
                font-family: 'Roboto', arial, verdana, sans-serif;
                text-decoration: none;
                text-overflow: ellipsis;
                text-align: center;
                line-height: 18px;
                color: #1d252c;
                font-weight: normal;
                font-style: normal;
                font-size: 16px;
                white-space: nowrap;
                max-width: 370px;
            }

            &.edi-module-tab-button-without-icon {
                .x-tab-inner {
                    max-width: 370px;
                }
            }

            &.edi-module-tab-button-with-icon {
                .x-tab-inner {
                    max-width: 345px;
                    //padding-left: 25px;
                }

                .x-tab-glyph {
                    color: #8e9295;
                }
            }

            .x-tab-close-btn {
                position: relative;
                display: none;
                vertical-align: middle;
                width: 14px;
                height: 14px;
                font-size: 0;
                line-height: 0;
                background-repeat: no-repeat;
                background-image: url("images/close-tab.png?ver=20150702");
                background-position: center;
            }

            &.x-tab-active {
                z-index: 100;
                background-color: #ffffff;
                box-shadow: 0 4px 8px rgba(187, 189, 191, 0.25);

                .x-tab-close-btn {
                    display: inline-block;
                }
            }

            &.x-tab-over,
            &.x-tab-over.x-tab-focus.x-tab-default {
                background-color: #f3f4f4;
                z-index: 1000;

                .x-tab-close-btn {
                    display: inline-block;
                    z-index: 1000
                }
            }
        }

        .x-tab:hover{
            z-index: 10;
        }

        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):before,
        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):last-child:after{
            position:         absolute;
            top:              8px;
            display:          inline-block;
            width:            1px;
            height:           24px;
            background-color: #d2d3d5;
            content:          "";
        }

        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):before{
            left: 0;
        }

        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):last-child:after{
            right: 0;
        }

        .x-tab:hover + .x-tab:not(.x-tab-active):before,
        .x-tab-active + .x-tab:before{
            visibility: hidden;
        }

        .x-tab.x-tab-active:after {
            left:             100%;
            background-image: url("images/tab/tab-active-right-bottom-angle.png");
        }

        .x-tab:not(:first-child):not(.x-tab-active):hover:before{
            right:            100%;
            background-image: url("images/tab/tab-hover-left-bottom-angle.png");
        }

        .x-tab:not(.x-tab-active):hover:after{
            left:             100%;
            background-image: url("images/tab/tab-hover-right-bottom-angle.png");
        }

        .x-tab:not(:first-child).x-tab-active:before{
            right:            100%;
            background-image: url("images/tab/tab-active-left-bottom-angle.png");
        }

        .x-tab.x-tab-active:after{
            left:             100%;
            background-image: url("images/tab/tab-active-right-bottom-angle.png");
        }

        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):before,
        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):last-child:after {
            position: absolute;
            top: 8px;
            display: inline-block;
            width: 1px;
            height: 24px;
            background-color: #d2d3d5;
            content: "";
        }

        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):before {
            left: 0;
        }

        .x-tab:not(:first-child):not(.x-tab-active):not(:hover):last-child:after {
            right: 0;
        }

        .x-tab:hover + .x-tab:not(.x-tab-active):before,
        .x-tab-active + .x-tab:before {
            visibility: hidden;
        }

        .x-tab:not(:first-child).x-tab-active:before,
        .x-tab.x-tab-active:after,
        .x-tab:not(:first-child):hover:before,
        .x-tab:hover:after {
            position: absolute;
            bottom: 0;
            display: inline-block;
            width: 7px;
            height: 6px;
            background-repeat: no-repeat;
            content: "";
        }

    }

    .edi-additional-tbar {
        .x-tab-bar {
            .x-tab-bar-body {
                background-color: #ffffff;
            }

            .x-tab-active {
                .x-tab-inner {
                    font-weight: bold;
                }
            }
        }
    }

    .edi-module-panel .tab-panel {
        .x-tab-bar-plain {
            height: 60px !important;
        }

        .edi-tab-bar.x-tab-bar {
            .x-tab {
                cursor: pointer;
                background-color: #ffffff;
                padding: 8px;
                margin: 10px 15px 10px 0;
                border-radius: 3px;
                -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
                border-collapse: separate;

                .x-tab-inner {
                    font-size: 13px;
                    font-weight: bold;
                    font-family: 'Roboto', arial, verdana, sans-serif;
                    color: #333333;
                    line-height: 24px;
                }

                &.x-tab-over,
                &.x-tab-over.x-tab-focus.x-tab-default {
                    background-color: #f3f4f4;
                }

                &.x-tab-active,
                &.x-tab-active.x-tab-over,
                &.x-tab-active.x-tab-over.x-tab-focus.x-tab-default {
                    background-color: #3d7dca;

                    .x-tab-inner,
                    .x-tab-glyph {
                        color: #ffffff;
                    }
                }

                &::before,
                &::after {
                    display: none !important;
                }
            }
        }
    }
}