/* -------- общие стили -------- */
.edi-viewport {
    &.edi-viewport-admin {
        .edi-main-header-panel {
            .x-panel-body {
                .x-panel-body-default {
                    background-color: #1d252c;
                }
            }
        }
    }

    .x-body{
        color:       black;
        font-size:   13px;
        font-family: 'Roboto', arial, verdana, sans-serif;
        background:  #f5f5f5;
    }

    .x-body,
    .x-panel-body-default,
    .x-panel-body-default-framed {
        font-weight: normal;
    }

    .x-panel-body {
        background-color: #ffffff;
        border-color: #157fcc;
        color: black;
        border-width: 0;
        border-style: solid;
    }

    .x-panel-header-default {
        background-image: none;
        background-color: #ffffff;

        .x-panel-header-title-default {
            .x-title-text {
                color: #212121;
                font-size: 18px;
                line-height: 22px;
                padding: 1px 0 0;
                text-transform: none;
            }
        }

        .x-tool {
            background-color: transparent;

            &.x-tool-disabled {
                opacity: 0.5
            }

            .x-mask {
                background-color: transparent;
            }

            .x-tool-tool-el {
                color: #757575;
                background-color: transparent;
                font-size: 20px;
                width: 20px;
                height: 20px;
            }

            &.x-tool-over,
            &.x-tool-pressed {
                background-color: transparent;

                .x-tool-tool-el {
                    color: #757575;
                }
            }
        }
    }

    .document-filter-header {
        .x-tool {
            background-color: transparent;

            &.x-tool-disabled {
                opacity: 0.5
            }

            .x-mask {
                background-color: transparent;
            }

            .x-tool-tool-el {
                color: #757575;
                background-color: transparent;
                font-size: 20px;
                width: 20px;
                height: 20px;
            }

            &.x-tool-over,
            &.x-tool-pressed {
                background-color: transparent;

                .x-tool-tool-el {
                    color: #757575;
                }
            }
        }
    }

    .tab-grid.edi-grid-with-row-lines, .modal-grid.edi-grid-with-row-lines {
        .x-panel-header-default {
            background-color: #fff;
            border-color: transparent;
        }
    }

    .x-border-layout-ct {
        background-color: #ececec;
    }

    .x-panel-default-framed {
        background: white;
        border-color: #157fcc;
        color: black;
        font-size: 13px;
        border-width: 0;
        border-style: solid;
    }

    .x-css-shadow {
        box-shadow: none !important;
    }

    .edi-viewport-mask-msg,
    .x-mask {
        background: rgba(33, 33, 33, 0.2);

        .x-mask-msg,
        .edi-viewport-mask {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;

            .x-mask-msg-inner {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                opacity: 0.2;
                background-image: url(./images/loadmask/loading.gif);
                background-repeat: no-repeat;
                background-position: center -12px;
                background-color: #ffffff;
                height: 130px;
                width: 200px;

                .x-mask-msg-text {
                    background-image: none;
                    padding: 0;
                }
            }
        }
    }

    .underlined {
        border-bottom: 2px solid #d0d0d0
    }

    .link-label {
        cursor: pointer;
        color: #08628c;
    }

    .x-btn-glyph,
    .x-action-col-glyph,
    .x-panel-header-glyph,
    .x-tab-glyph,
    .x-trigger-glyph,
    .x-column-header-sort-glyph,
    .x-action-col-icon,
    .edi-viewport .tab-grid .x-btn-glyph {
        display: inline-block;
        vertical-align: middle;
        font-weight: normal;
        font-style: normal;
        font-size: 20px;
        line-height: 20px;
        height: 20px;
        width: 20px;
        text-transform: none;
        color: #8e9295;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
    }

    a.x-btn.x-btn-default-small,
    a.x-btn.x-btn-default-toolbar-small {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;

        .x-btn-inner {
            font-size: 14px;
            color: #1d252c;
        }

        .x-btn-glyph {
            font-size: 20px;
        }

        &.x-btn-over {
            background-color: #f3f4f4;
        }

        &.x-btn-pressed {
            background-color: #e8e9e9;
            &.test-button-details {
                background: transparent;
            }
        }

        &.x-btn-over,
        &.x-btn-pressed {
            box-shadow: none;

            .x-btn-glyph,
            .x-btn-inner {
                color: #1d252c;
            }
        }

        &.x-btn-pressed,
        &.x-btn-over,
        &.x-btn-focus,
        &.x-btn-focus.x-btn-pressed,
        &.x-btn-focus.x-btn-over {
            box-shadow: none;
        }

        .x-btn-arrow{
            background: none;
        }
        .x-btn-arrow-right{
            padding-right: 20px;
            position: relative;
            &:after {
                content:         "\E5C5";
                font-family:     'Material Icons';
                font-style:      normal;
                font-weight:     normal;
                text-decoration: inherit;
                position:        absolute;
                font-size:       20px;
                color:           #1d252c;
                right:           0;
                top:             0;
            }
        }
        &.x-btn-menu-active {
            .x-btn-arrow-right{
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        &.x-item-disabled {
            .x-btn-glyph,
            .x-btn-inner,
            .x-btn-arrow-right:after {
                color: #bbbdbf;
            }
            &.x-btn{
                cursor: default;
            }
        }
    }

    a.x-btn.edi-label-button {
        &.no-text-decoration,
        &.test-button-remove,
        &.test-button-print {
            background-color: transparent;
            padding: 12px 10px;
            &.x-btn-over {
                background-color: transparent;
            }
        }
        &.test-button-print {
            width: auto !important;
            height: auto !important;
            top: 0 !important;
        }
        padding: 12px 32px;
        color: #1d252c;
        line-height: 16px;
        text-transform: uppercase;
        background-color: #d2d3d5;
        border-radius: 3px;
        &.x-btn-over {
            background-color: #bbbdbf;
        }
    }

    a.x-btn.edi-actions-button {
        padding: 10px;
        border: none;
        outline: none;
        border-radius: 3px;
        float: right;
        margin-left: 3px;

        .x-btn-glyph {
            width: 20px;
            height: 20px;
        }

        &.x-btn-over {
            background-color: #f3f4f4;
        }

        &.x-btn-pressed {
            background-color: #e8e9e9;
        }

        &.x-btn-over,
        &.x-btn-pressed {
            box-shadow: none;

            .x-btn-glyph {
                color: #1d252c;
            }
        }

        .x-btn-arrow-right {
            padding-right: 0;
            .x-btn-button {
                padding-right: 0;
            }

            &::after {
                content: '';
                width: 0;
            }
        }
    }

    a.x-btn.requirements-pas {
        cursor: default;
        padding: 0;
        .x-btn-inner {
            font: normal 12px "Roboto", arial, verdana, sans-serif;
            color: rgba(0, 0, 0, 0.5);
        }
        &.x-btn-over {
            background-color: transparent;
            .x-btn-inner, .x-btn-icon-el {
                color: rgba(0, 0, 0, 0.5);
            }
        }
        &-true {
            .x-btn-glyph {
                color: #009900;
            }
        }

    }
    a.x-btn.requirements-pas:not(:first-child) {
        margin-top: 5px;
    }

    .requirements-title {
        font: normal 12px "Roboto",arial,verdana,sans-serif;
        color: rgba(0,0,0,.5);
        font-weight: bold;
        font-size: 1em;
    }

    .x-toolbar-footer {
        background: #ffffff;
        border: 0;
        margin: 0;
        padding: 6px 0 6px 6px;

        a.x-btn,
        a.x-btn.x-btn-over,
        a.x-btn.x-btn-pressed {
            border-radius: 3px;
            padding: 10px;
            background-color: #3d7dca;

            .x-btn-inner,
            .x-btn-glyph {
                color: #ffffff
            }
        }
    }

    .dir-outgoing,
    .dir-incoming,
    .dir-toself,
    .dir-notdefined {
        display: inline-block;
        padding-left: 14px;
        width: 14px;
        height: 14px;
        overflow: hidden;
    }

    .dir-incoming {
        color: #009900;
        background-image: url(images/dir-incoming.png?ver=20150702);
    }

    .dir-outgoing {
        color: #0066ff;
        background-image: url(images/dir-outgoing.png?ver=20150702);
    }

    .dir-toself {
        color: #515151;
        background-image: url(images/toself.png?ver=20150702);
    }

    .dir-notdefined {
        color: #a1a1a1;
        background-image: url(images/notdefined.png?ver=20150702);
    }

    .edi-data-error, .error-description, .edi-data-error {
        color: #ff0000;
    }

    .reset-ecd-version {
        &:before {
            color: #f34235;
        }
    }

    //documents state
    .edi-state-container {
        display: inline-block;
        max-width: 100%;
        padding: 2px 10px;
        font-size: 13px;
        line-height: normal;
        border-style: solid;
        border-width: 1px;
        border-radius: 30px;
        box-shadow: 0 4px 8px rgb(187 189 191 / 25%);
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: "Roboto",arial,verdana,sans-serif !important;
    }
    .edi-state-container-COMPLETED,
    .edi-state-container-ARCHIVED,
    .edi-state-container-ACCEPTED,
    .edi-state-container-ACTIVE,
    .edi-state-container-ADMIN_REVIEW,
    .edi-state-container-APERAK_CODE_13,
    .edi-state-container-APERAK_CODE_29,
    .edi-state-container-APERAK_CODE_34,
    .instruction-state-ab-EXECUTED,
    .edi-state-container-APERAK_STATUS_29_APERAK_CODE{
        border-color: #00ac34;
    }
    .status-COMPLETED,
    .status-ARCHIVED,
    .status-ACCEPTED,
    .status-ACTIVE,
    .status-ADMIN_REVIEW,
    .status-APERAK_CODE_13,
    .status-APERAK_CODE_29,
    .status-APERAK_CODE_34,
    .instruction-state-ab-EXECUTED,
    .status-APERAK_STATUS_29_APERAK_CODE{
        color: #00ac34;
    }

    .edi-state-container-SELLER_REVIEW,
    .edi-state-container-DEBTOR_REVIEW,
    .edi-state-container-SENDER_REVIEW,
    .edi-state-container-RECEIVER_REVIEW,
    .edi-state-container-SENT,
    .edi-state-container-READ,
    .edi-state-container-WAIT_BANK_DECISION,
    .edi-state-container-WAIT_BANK_COMPLETE,
    .edi-state-container-SIGNED_BY_CONSUMER,
    .edi-state-container-UPD_NOTICE_COMPLETED,
    .edi-state-container-ACCEPTED,
    .edi-state-container-ACCEPTED-registry-line,
    .edi-state-container-UPD_NOTICE_SENT,
    .edi-state-container-PROCESSING_TITLES,
    .edi-state-container-PROCESSING_ANNUL,
    .edi-state-container-OUTGOING_DSF_DP_IZVPOL_RECEIVER,
    .edi-state-container-INCOMING_DSF_DP_IZVPOL_SENDER,
    .edi-state-container-APERAK_STATUS_6_APERAK_CODE,
    .edi-state-container-APERAK_STATUS_13_APERAK_CODE{
        border-color: #3d7dca;
    }
    .status-SELLER_REVIEW,
    .status-DEBTOR_REVIEW,
    .status-SENDER_REVIEW,
    .status-RECEIVER_REVIEW,
    .status-SENT,
    .status-READ,
    .status-WAIT_BANK_DECISION,
    .status-WAIT_BANK_COMPLETE,
    .status-SIGNED_BY_CONSUMER,
    .status-UPD_NOTICE_COMPLETED,
    .instruction-state-ab-ACCEPTED,
    .status-ACCEPTED-registry-line,
    .status-UPD_NOTICE_SENT,
    .status-PROCESSING_TITLES,
    .status-PROCESSING_ANNUL,
    .status-OUTGOING_DSF_DP_IZVPOL_RECEIVER,
    .status-INCOMING_DSF_DP_IZVPOL_SENDER,
    .status-APERAK_STATUS_6_APERAK_CODE,
    .status-APERAK_STATUS_13_APERAK_CODE{
        color: #3d7dca;
    }

    .edi-state-container-UTOCH,
    .edi-state-container-ANNUL,
    .edi-state-container-SENT_DP_PRANNUL_TO_RECEIVER,
    .edi-state-container-REJECTED,
    .edi-state-container-SENDING_TO_BANK_ERROR,
    .edi-state-container-BANK_DECISION_ERROR,
    .edi-state-container-ERROR,
    .edi-state-container-APERAK_CODE_27,
    .edi-state-container-SIGNED_BY_CONSUMER_short_3,
    .edi-state-container-SIGNED_BY_CONSUMER_3,
    .edi-state-container-COMPLETED_3,
    .edi-state-container-REJECTED,
    .edi-state-container-ERROR_ANNUL,
    .edi-state-container-DEACTIVATED,
    .edi-state-container-DELETED,
    .edi-state-container-APERAK_STATUS_27_APERAK_CODE,
    .edi-state-container-APERAK_CODE_27_DOCUMENT_NAME_CODE_222 {
        border-color: #e82000;
    }
    .status-UTOCH,
    .status-ANNUL,
    .status-SENT_DP_PRANNUL_TO_RECEIVER,
    .status-REJECTED,
    .status-SENDING_TO_BANK_ERROR,
    .status-BANK_DECISION_ERROR,
    .status-ERROR,
    .status-APERAK_CODE_27,
    .instruction-state-ab-REJECTED,
    .status-DEACTIVATED,
    .status-ERROR_ANNUL,
    .status-SIGNED_BY_CONSUMER_short_3,
    .status-SIGNED_BY_CONSUMER_3,
    .status-COMPLETED_3,
    .status-DELETED,
    .status-APERAK_STATUS_27_APERAK_CODE,
    .status-APERAK_CODE_27_DOCUMENT_NAME_CODE_222{
        color: #e82000;
    }

    .edi-state-container-SIGNED_BY_CONSUMER_short_2,
    .edi-state-container-SIGNED_BY_CONSUMER_2,
    .edi-state-container-COMPLETED_2,
    .edi-state-container-APERAK_STATUS_23_APERAK_CODE{
        color: darkorange;
    }
    .status-SIGNED_BY_CONSUMER_short_2,
    .status-COMPLETED_2,
    .status-APERAK_STATUS_23_APERAK_CODE{
        color: darkorange;
    }

    .edi-state-container-DRAFT{
        border-color: #8e9295;
    }
    .status-DRAFT{
        color: #8e9295;
    }

    .edi-state-container-CODES_SENT,
    .edi-state-container-SUCCESS_ANNUL,
    .edi-state-container-APERAK_STATUS_34_APERAK_CODE{
        border-color: #00AFAB;
    }
    .status-CODES_SENT,
    .status-SUCCESS_ANNUL,
    .status-APERAK_STATUS_34_APERAK_CODE{
        color: #00AFAB;
    }

    .edi-state-good {
        color: #090;
        font-weight: 700;
    }
    .edi-state-error {
        color: red;
        font-weight: 500;
        &.edi-empty-label {
            color: red;
        }
    }

    .documents-list {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        th, td {
            text-align: right;
        }
        td {
            padding: 2px;
        }
    }

    .textarea-fit {
        .x-form-item-label {
            margin:      0;
            font-size:   15px;
            font-weight: 600;
        }
        .x-form-textarea-body {
            textarea.x-form-text {
                margin:  0;
                padding: 5px;
                border: 1px solid #c0c0c0;
                box-shadow: none;
            }
        }
    }

    .x-btn.x-form-file-btn {
        border-radius: 3px;
        padding: 10px;
        background-color: #3d7dca;
        &.x-btn-focus {
            box-shadow: none;
        }
    }

    .x-tree-panel, .x-tree-arrows {
        .x-tree-view {
            .x-tree-icon, .x-tree-icon-leaf {
                display: none;
            }
            .x-tree-expander {
                color: #8a8b8b;
            }
            .edi-linked-current-element {
                background-color: #d5e6ed;
            }
            .x-grid-item-over {
                background-color: #f7f7f7;
                .x-grid-cell {
                    background-color: #f7f7f7;

                    .x-action-col-icon {
                        background-color: #f3f4f4;
                    }
                }
            }
        }
    }

    .is-edit {
        .editable {
            .x-grid-cell-inner {
                border: 1px solid #d2d3d5;
                box-sizing: border-box;
                border-radius: 3px;
                margin: 0 20px;
                padding: 4px 0 4px 8px;
            }
        }
    }
    .x-editor.x-grid-editor {
        overflow: visible;
        .x-field {
            .x-form-text{
                position:      relative;
                font:          normal 13px/15px 'Roboto', arial, verdana, sans-serif;
                padding:       4px 9px 3px 9px;
                border:        2px solid #3d7dca;
                box-sizing:    border-box;
                border-radius: 3px;
                height:        33px;
                margin-top:    2px;
                min-width:     150px!important;
            }
            .x-form-trigger-spinner, .x-form-arrow-trigger {
                display: none;
            }
        }
    }

    .x-tab-icon-el {
        opacity: 1;
        width: 24px;
        height: 24px;
    }

    .edi-timer-enabled {
        .x-grid-td {
            background-color: rgba(186,239,186,.4);
        }
    }
    .edi-timer-disabled {
        .x-grid-td {
            background-color: rgba(186,186,186,.2);
        }
    }
    .edi-timers-global-switch-disabled label {
        background-color: rgba(239,186,186,.4);
        line-height: 20px;
    }
    .edi-timers-global-switch-enabled label {
        background-color: rgba(186,239,186,.4);
        line-height: 20px;
    }
    .x-panel-header-default {
        .font-icon-white, .font-icon-white.x-tool-over{
            .x-tool-tool-el {
                color: #ffffff;
            }
        }
    }

    .serverErrorDetailsText {
        font-style: italic;
        padding-top: 5px;
        color: red;
        font-size: 10px;
    }

    .x-btn-icon-left {
        justify-content: flex-start;
    }

    .edi-data-good {
        color: #090;
    }
    .edi-info {
        color: #06f;
        font-weight: 700;
    }

    .edi-storage-cluster-node-master {
        .x-grid-cell-inner {
            font-weight: 700;
        }
    }

    .edi-cart-series-toggle-disabled{
        opacity: 0.7
    }

    .x-tip-default, .x-tip-form-invalid {
        background-color: #eaf3fa;
        border-color: #e1e1e1;
        .x-tip-body-default, .x-tip-body-form-invalid {
            color: #000000;
            font-weight: 400;
            padding: 3px;
        }
    }

    .x-tip-form-invalid {
        width: 310px !important;
    }
    .x-tip-form-invalid, .x-tip-default {
        .x-tip-body-form-invalid {
            width: 100% !important;
            background: unset;
            display: flex;
            &:before {
                color: #D94E37;
                font-family: Material Icons;
                font-size: 17px;
                content: '\e000';
                margin-right: 5px;
            }
        }
        .x-tip-anchor {
            overflow: hidden;
            height: 10px;
            width: 10px;
            border: 5px solid #e1e1e1;
        }
        .x-tip-anchor-top {
            border-top-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
        }
    }

    .edi-tooltip-notify {
        width:            300px;
        background-color: #ffffff;
        color:            #000000;
        border-radius:    10px;
        padding:          15px;
        height:			  71px;
        position:         fixed;
        //vertical-align:		top;
        display:           flex;
        top: 			  60px;
        //float: 				right;
        z-index:          10000;
        transition:       opacity 0.3s;
        box-shadow:       0px 4px 16px rgba(187, 189, 191, 0.5);
        &:after {
            display:          flex;
            content:          '';
            position:         absolute;
            width:            12px;
            height:           12px;
            left:             12%;
            top:              0;
            transform:        translate(-50%,-50%) rotate(-45deg);
            background-color: #ffffff;
            border:           1px solid #ffffff;
        }
        &:before{
            font-family:    'Material Icons';
            content:        "\e88e";
            font-size:      22px;
            padding-right:  5px;
            color: red;
            //display:  inline-flex;
            //vertical-align: middle;
        }
        & + .x-btn.edi-tooltip-notify-close-btn {
            margin-left: 		271px;
            margin-top: 		25px;
            position: 			fixed;
            z-index:            10000;
            .x-btn-icon {
                .x-btn-glyph {
                    color: #8e9295 !important;
                }
            }
        }
        span {
            white-space: normal;
        }
    }

    .edi-formtr-panel {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 9999999;
        border-radius: 3px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, .2);
        overflow: hidden;
        background: #FFF;
        padding: 15px;
    }

    .text-container-no-flex {
        .x-header-text{
            cursor: pointer;
        }
        .x-header-text{
            margin-right: 10px;
        }
    }

    .edi-grey-icon {
        .x-btn-glyph,
        .x-tool-tool-el {
            color: #8e9295 !important;
        }
    }
    .edi-white-button {
        .x-btn-glyph,
        .x-tool-tool-el {
            color: #fff!important;
        }
    }
    .font-icon-white{
        color: #ffffff;
    }
    .font-icon-disable {
        color: #e3e3e3;
    }
    a.x-btn.x-btn-default-small.edi-packing-btn {
        border-radius: 3px;
        padding: 10px;
        background-color: #3d7dca;
        .x-btn-inner, .x-btn-icon-el {
            color: #fff;
        }
    }
    .edi-package-buttons {
        margin-top: 25px;
        .x-autocontainer-innerCt {
            display: flex;
            justify-content: flex-end;
        }
    }

    .edi-button-add {
        &:before {
            font-size: 20px;
            content: '\e148';
            font-family: 'Material Icons', sans-serif;
        }
    }

    .edi-window-notice {
        display: block;
        position: absolute;
        z-index: 20000 !important;
        right: 25px !important;
        top: 70px;
        margin: 25px;
        background: white;
        border-radius: 3px;
        box-shadow: 0px 4px 16px rgba(187, 189, 191, 0.5);

        .edi-main-container { /* used when no ico */
            margin-left: 14px;
        }

        .edi-window-notice-body {
            padding: 5px 5px 0px 5px;

            .edi-leftside-ico {
                font-family: 'Material Icons';
                font-size: 24px;
                line-height: 24px;
                padding: 5px;
            }

            .edi-rightside-main-container {
                margin-left: 8px;

                .edi-rightside-title,
                .edi-rightside-main-text,
                .edi-rightside-main-text-additional {
                    font-family: 'Roboto', arial, verdana, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    letter-spacing: 0;
                }

                .edi-rightside-title {
                    font-size: 20px;
                    line-height: 28px;
                    color: #1d252c;
                    padding: 3px 5px;
                    overflow: auto;
                }

                .edi-rightside-main-text,
                .edi-rightside-main-text-additional {
                    font-size: 14px;
                    line-height: 20px;
                    color: #333a41;
                    padding: 5px;

                    p {
                        margin-block-start: 0;
                        margin-block-end: .3em;
                        margin-inline-start: 0;
                        margin-inline-end: 0;

                    }
                }

                .edi-rightside-vertical-toolbar {
                    padding: 6px 0 0;
                    display: inline;
                    position: absolute;

                    .x-btn-default-small {
                        padding: 0;
                    }
                }
            }
        }

        .edi-bottom-toolbar {
            padding: 0 15px 0;

            .x-btn {
                box-shadow: none;
                background: none !important;
                border: none;

                .x-btn-inner {
                    font-family: 'Roboto', arial, verdana, sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    font-size: 14px;
                    text-align: center;
                    letter-spacing: 0.75px;
                    text-transform: uppercase;
                    color: #0e85f3 !important;
                }
            }
        }
    }

    .edi-info-state-container{
        display:       inline-block;
        max-width:     100%;
        padding:       2px 10px;
        font-size:     13px;
        line-height:   normal;
        overflow:      hidden;
        text-overflow: ellipsis;
        font-family: 'Roboto', arial, verdana, sans-serif!important;
    }

    .edi-grid-selection {
        padding: 20px 8px;
        background-color: #ffffff;
        color: #3d7dca;
        font-weight: 500;

        .x-btn-default-toolbar-small {
            padding: 0;

            .x-btn-glyph {
                color: #757575;
            }
        }

        .x-btn-default-toolbar-small-disabled {
            opacity: 0.2;
        }
    }
    .edi-storage-metrics {
        padding: 0 !important;
        .edi-storage-metrics-header{
            background: #d1d1d1;
            .edi-actions-button{
                padding: 0 10px 0 0;
            }
        }
    }
}