/* -------- edi-document-header-panel-row - панель хэдера просмотра документа -------- */
.edi-document-header-panel-row {
    .x-btn-default-small {
        padding: 0;
    }

    .edi-state-container {
        box-shadow: none;
        border: none;
        padding: 0;
        display: inline;
        overflow: auto;
        line-height: 16px;
    }
}