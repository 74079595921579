/* -------- windows  -------- */
.edi-viewport {
    .x-window-default {
        border: none;
        border-radius: 0;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);

        .x-window-header-default-horizontal {
            .x-tool-after-title {
                margin: 0 0 0 6px !important;
            }
        }

        .x-window-header {
            border-color: #3d7dca;
            background-color: #3d7dca;
            padding: 5px;
            border-width: 5px !important;

            > .x-box-inner {
                height: 20px !important;

                .x-window-header-title {
                    color: #ffffff;
                    font-weight: bold;
                    line-height: 15px;
                    font-family: 'Roboto', sans-serif;
                    font-size: 13px;
                    top: 0 !important;
                    padding: 1px 0 0;
                    text-transform: none;
                }

                .x-tool-close {
                    font-size: 20px;
                    line-height: 20px;
                    height: 20px;
                    width: 20px;

                    &::before {
                        content: "\e5c9";
                        font-family: 'Material Icons', sans-serif;
                    }
                }
            }
        }

        .x-window-body-default {
            border-color: #ffffff;
            border-width: 0;
            border-style: solid;
            background: white;
            color: black;

            .msg-width-correction {
                min-width: 520px;
            }

            .x-message-box-question {
                font-size: 35px;
            }

            .x-component-default.x-window-text {
                font-weight: 400;
            }

            .x-container-default {
                .ui-grid-body {
                    .x-autocontainer-innerCt {
                        .ui-core-datefield {
                            width: 140px;
                        }
                        .edi-form-field-date-range-delimiter {
                            width: unset;
                        }
                        //div:last-child {
                        //	margin-bottom: 20px;
                        //}
                    }
                }
            }

            .tab-grid {
                .x-panel-header {
                    background-color: #FFFFFF;
                    .x-panel-header-title {
                        .x-title-text {
                            color: #212121;
                        }
                    }
                }
            }

            .x-panel.tab-panel {
                .x-panel-body.x-panel-body-default {
                    .edi-value-display-input {
                        border-bottom: 1px solid gray;
                        padding-left: 5px;
                        line-height: 24px;
                        height: 24px;
                    }
                    .x-form-display-field {
                        font: normal 13px/17px "Roboto",arial,verdana,sans-serif;
                        color: #000;
                        margin-top: 4px;
                        min-height: 0;
                    }
                    .modal-grid {
                        .x-box-target {
                            .x-tool-default.x-tool-after-title{
                                //padding: 8px 0 8px 10px;
                            }
                        }
                        &.modal-grid-with-header {
                            .x-title-text {
                                display: block;
                            }
                        }
                        .x-title-text {
                            display: none;
                        }
                        .x-panel-header {
                            background-color: transparent;
                            .x-panel-header-title {
                                .x-title-text{
                                    color: #212121;
                                }
                            }
                            .x-box-target {
                                position: absolute;
                                width:    20000px;
                                top:      0px;
                                left:     0;
                                height:   1px;
                            }
                        }
                        .x-panel-bodyWrap {
                            .x-toolbar {
                                .edi-grid-settings-button {
                                    background: #fff;
                                }
                            }
                            .x-panel-body {
                                .x-grid-body {
                                    .x-grid-item-container {
                                        border: 0;
                                        border-top-width: 2px;
                                        border-style: solid;
                                        border-color: rgba(29,37,44,.15);
                                        .x-grid-item {
                                            .x-grid-td {
                                                border-bottom-width: 1px;
                                                &:first-child {
                                                    border-left: 4px solid transparent;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ui-core-multifield, .ui-core-combofield {
                width: 100%;
            }
        }
        .x-window-handle.x-window-handle-east{
            right: -8px;
        }
    }

    .edi-modal-preview {
        z-index: 90300 !important;
    }

    .edi-admin-resend-documents-results-table {
        width: 100%;
        border-collapse: collapse;

        td {
            padding: 4px;
            border: 1px solid gray;
        }
    }

    .crpt-check-modal {
        .crpt-check-modal__content-container {
            padding: 20px 20px 0 20px;

            .crpt-check-waiting-icon {
                width: 130px;
                height: 130px;
                line-height: 130px;
                background-image: url(images/loadmask/loading.gif);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center -12px;
            }

            .crpt-check-error-icon {
                width: 130px;
                height: 130px;
                line-height: 130px;
                background-image: url(images/shared/icon-attention.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }

        .crpt-check-modal__buttons-container {
            padding: 20px;
        }

        a.x-btn.edi-label-button,
        a.x-btn.crpt-check-modal__crpt_site_link {
            background-color: transparent;
            text-decoration: underline;
            text-transform: unset;
            color: #3D7DCA;
            padding: 2px;
            .x-btn-inner-default-small {
                color: inherit;
            }
        }

        .edi-label-button.x-btn-over,
        .crpt-check-modal__crpt_site_link {
            color: #1455a2;
        }

        .edi-label-button.x-btn-pressed,
        .crpt-check-modal__crpt_site_link {
            color: #3D7DCA;
        }

        a.x-btn.crpt-check-modal__marking-auth-form__enter-button,
        .crpt-check-modal__download-error-report {
            border-radius: 3px;
            padding: 10px;
            background-color: #3d7dca;
            color: #ffffff;
        }

        @keyframes loader_ring {
            0% {
                transform: translate(-50%, -50%) rotate(0deg);
            }
            100% {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }

        .crpt-check-modal__marking-auth-form__enter-button__loading::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 6px solid #2600ff;
            border-color: #2600ff #2600ff #2600ff transparent;
            animation: loader_ring 1.2s linear infinite;
        }

        .crpt-check-modal__download-error-report .x-btn-glyph {
            color: #ffffff;
        }
    }

    .x-content-box {
        .x-progress-default{
            height: 20px;
        }
    }

    .x-progress-default {
        .x-progress-bar-default {
            background-color: #c1ddf1;
        }
        .x-progress-text{
            color:       #666666;
            font-weight: bold;
            font-size:   13px;
            text-align:  center;
            line-height: 20px;
        }
    }

    .x-progress {
        position:              relative;
        border:                1px solid #3d7dca;
        height:                20px;
        overflow:              hidden;
        //-webkit-border-radius: 2px;
        //-moz-border-radius:    2px;
        //-ms-border-radius:     2px;
        //-o-border-radius:      2px;
        border-radius:         2px;
        margin-top: 10px !important;
        .x-progress-bar {
            overflow: hidden;
            position: absolute;
            width:    0;
            height:   100%;
        }
        .x-progress-text{
            overflow: hidden;
            position: absolute;
            //???
            height: 0;
        }
    }
}